import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalDialogConfirmation } from '../main/indexado/hotspots/hotspots-components/modalTypes/modal-confirm.component'
import { Injectable } from '@angular/core';
import { ModalDialogError } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-error.component';
import { ModalDialogInfo } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-info.component';
import { ModalEditKSPComponent } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-edit-ksp/modal-edit-ksp.component';
import { ModalEditTEXTComponent } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-edit-text/modal-edit-text.component';
import { ModalEditGALLERYComponent } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-edit-gallery/modal-edit-gallery.component';
import { ModalAddNewElementComponent } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-add-new-element/modal-add-new-element.component';
import { ETypeModalDialog } from 'app/main/indexado/hotspots/hotspots.model';
import { ModalBulkloadHotspotsComponent } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-bulkload-hotspots/modal-bulkload-hotspots.component';
import { ModalSelectFormatHotspotsComponent } from 'app/main/indexado/hotspots/hotspots-components/modalTypes/modal-select-format-hotspots/modal-select-format-hotspots.component'

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public modal = ETypeModalDialog

  constructor(public dialog: MatDialog) {}

  selectModalType(modalType:string, requestData:any, cbFunc?: any): void {
    let dialogRef:any
    let dialogConfig = new MatDialogConfig()

    switch(modalType) {
      case this.modal.EDIT_KSP_MODAL:
        dialogRef = this.dialog.open(ModalEditKSPComponent, {
          width: '750px',
          data: requestData,
        });
        break;
      case this.modal.EDIT_TEXT_MODAL:
        dialogRef = this.dialog.open(ModalEditTEXTComponent, {
          width: '750px',
          data: requestData,
        });
        break;
      case this.modal.EDIT_GALLERY_MODAL:
        dialogRef = this.dialog.open(ModalEditGALLERYComponent, {
          width: '750px',
          data: requestData,
        });
        break;
      case this.modal.BUTTONCONFIRM:
        dialogConfig.position = {
          top: '10'
        }
        dialogConfig.data = requestData
        dialogRef = this.dialog.open(ModalDialogConfirmation, dialogConfig);
        break;
      case this.modal.INFOMODAL:
        dialogConfig.data = requestData
        dialogRef = this.dialog.open(ModalDialogInfo, dialogConfig)
        break;
      case this.modal.ERRORMODAL:
        dialogConfig.data = requestData
        dialogRef = this.dialog.open(ModalDialogError, dialogConfig)
        break;
      case this.modal.BULKLOAD_HOTSPOTS_MODAL:
        dialogRef = this.dialog.open(ModalBulkloadHotspotsComponent, {
          width: '950px',
          data: requestData,
        });
        break;
      case this.modal.ADD_NEW_ELEMENT:
        dialogRef = this.dialog.open(ModalAddNewElementComponent, {
          width: '600px',
          data: requestData
        });
      case this.modal.SELECT_HOTSPOTS_FORMAT:
        dialogRef = this.dialog.open(ModalSelectFormatHotspotsComponent, {
          width: '600px',
          data: requestData
        });   
      default:
        // code block
    }
    dialogRef.afterClosed().subscribe(result => {
      if (cbFunc)
        cbFunc(result)
    });
  }
}
