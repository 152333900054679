import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import { CampaignService } from '../../../../services/campaign.service';
import { IndexadoService } from '../../../../services/indexado.service'
import * as moment from 'moment-timezone';
import { ICampaign, ICampaignResponse, IVersion, IElement, ILang } from '../../project.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  id: string;
  form: FormGroup;
  displayedColumns: string[] = ['version', 'actived', 'selected'];
  dataSourceVersions: MatTableDataSource<any>;

  versions: IVersion[];
  hours: string[];
  utcs: string[];
  languages: ILang[];

  selectedLanguage: ILang


  skus: IElement[];
  urls: IElement[];
  urlNotShow: IElement[];

  campaign: ICampaign;

  filters: {
    languaje: string,
    version: string,
    name: string
  }

  separators: {
    skus: string,
    urls: string,
    urlNotShow: string  
  }

  inputsChips: {
    skus: string,
    urls: string,
    urlNotShow: string
  }

  customNames: string[];
  
  constructor(
    private campaignService: CampaignService,
    private indexadoService: IndexadoService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.versions = [];
    
    this.dataSourceVersions = new MatTableDataSource(this.versions);

    this.hours = [];
    for(let i = 0;i < 24; i++) {
      for (let j = 0; j < 60; j+=15) {
        const NEW_HOUR = `${i < 10 ? '0' : ''}${i}:${j < 15 ? '0':''}${j}`
        this.hours.push(NEW_HOUR);
      }
    }
    
    this.utcs = moment.tz.names();

    this.languages = [
      { flag:'chile' , country:'Chile' , code:'esCL' , title:'CL' },
      { flag:'colombia' , country:'Colombia' , code:'esCOL' , title:'COL'},
      { flag:'peru' , country:'Peru' , code:'esPE'  , title:'PE'},
      { flag:'costa-rica' , country:'Costa Rica' , code:'esCAC' , title:'CAC'},
      { flag:'mexico' , country:'Mexico' , code:'esMX', title:'MX' },
      { flag:'brazil' , country:'Brazil' , code:'PT', title:'BR' },
    ];

    this.selectedLanguage = this.languages[0];
    this.filters = {
      name: '',
      version: '',
      languaje: ''
    }

    this.separators = {
      skus: '',
      urls: '',
      urlNotShow: ''
    }
    this.skus = [];
    
    this.inputsChips = {
      skus: '',
      urls: '',
      urlNotShow: ''
    }
    
    if (!this.id) {
      this.urls = [];
  
      this.urlNotShow = [];
      this.campaign = {
        dateNotLimit: false,
        endDate: new Date().toString(),
        endHour: '',
        campaignId: '',
        name: '',
        startDate: new Date().toString(),
        startHour: '',
        urls: [],
        urlNotShow: [],
        utc: '',
        version: '',
        skus: []
      }
    } else {
      this.campaignService.get(this.id).subscribe((resp: {data: ICampaign}) => {
        this.campaign = {
          campaignId: resp.data.campaignId,
          name: resp.data.name,
          startDate: resp.data.startDate,
          startHour: resp.data.startHour,
          endDate: resp.data.endDate,
          endHour: resp.data.endHour,
          urls: resp.data.urls,
          urlNotShow: resp.data.urlNotShow,
          dateNotLimit: resp.data.dateNotLimit,
          utc: resp.data.utc,
          version: resp.data.version,
          skus: resp.data.skus
        };
        this.urls = resp.data.urls.map((url: string) => {
          return {
            text: url,
            selected: false
          }
        });
        this.urlNotShow = resp.data.urlNotShow.map((url: string) => {
          return {
            text: url,
            selected: false
          }
        });
        this.skus = resp.data.skus.map((sku: string) => {
          return {
            text: sku,
            selected: false
          }
        });
        const arr = resp.data.version.split("_");
        this.filters.languaje = 'esCL',
        this.filters.version = arr[0];
        this.campaignService.getVersionsCustomNames(this.selectedLanguage.code, this.filters.version)
        .subscribe((resp2: {data: string[]}) => {
          this.customNames = resp2.data;
          this.filters.name = arr[1];
          this.callCustomVersionsName();
        })
      })
    }

        // Reactive Form
    this.form = this._formBuilder.group({
      name: ['', Validators.required],
      startDate: ['', Validators.required],
      startHour: ['', Validators.required],
      endDate: ['', Validators.required],
      endHour: ['', Validators.required],
      utc: ['', Validators.required],
      separator1: [''],
      separator2: [''],
      separator3: [''],
      version: [''],
      searchTextChanged: ''
    });
  }

  onChange(event: any, property: string) {
   this.campaign[property] = event.target ? event.target.value : event.value ? event.value : event.checked;
  }

  onChangeFilter(event: any, property: string) {
    this.filters[property] = event.target ? event.target.value : event.value;
    if (property !== 'name' && this.filters.version) {
    
      this.campaignService.getVersionsCustomNames(this.selectedLanguage.code, this.filters.version)
        .subscribe((resp: {data: string[]}) => {
          this.customNames = resp.data;
        })
    } else {
          if (property === 'name') {
            this.callCustomVersionsName();
          }
    }
  }

  callCustomVersionsName() {
    this.indexadoService.getSkusByVersion(`${this.selectedLanguage.code}_${this.filters.version}_${this.filters.name}`)
    .subscribe((resp: { status: number, data: string[]}) => {
      if (resp.status === 200) {
        this.versions = resp.data.map((v: string) => {
          return {
            actived: true,
            id: `${v}_${this.selectedLanguage.code}_${this.filters.version}_${this.filters.name}`,
            selected: true,
          }
        });
      } else {
        this.versions = [];
      }
      this.dataSourceVersions = new MatTableDataSource(this.versions);
    })
  }

  onLoadData(separator: string) {
    if (!this.separators[separator]) {
      alert("Debe seleccionar el separador");
    } else {
      if (!this.inputsChips[separator]) {
        alert("Debe agregar la información a cargar separada por el separador seleccionado");
      } else {
        const arr = this.inputsChips[separator].split(this.separators[separator]);
        arr.forEach((item: string) => {
          if (item) {
            const obj = this[separator].find((elem: IElement) => elem.text === item)
            if (!obj) {
              this[separator].push({
                text: item,
                selected: false
              }) 
            }
          }
        });
        this.inputsChips[separator] = '';
      }
    }
  }

  onChangeSeparators(value: string, property: string) {
    this.separators[property] = value;
  }

  onChangeInputsChips(event: any, property: string) {
    this.inputsChips[property] = event.target ? event.target.value : event.value;
  }

  onRemove(arr: string) {
    this[arr] = this[arr].filter((m: IElement) => !m.selected);
  }

  onSelect(element: IElement) {
    element.selected = !element.selected
  }

  onSelectAll() {
    this.versions.forEach((v: IVersion) => {
      v.selected = true
    });
  }

  onSelectVersion(event: any, version: IVersion) {
    const v = this.versions.find((ve: IVersion) => ve === version);
    v.selected = event.checked;
  }

  save() {
    if (!this.campaign.name) {
      alert('Por favor coloque el nombre de la campaña');
      return false;
    }
    if (!this.campaign.startDate) {
      alert('Por favor coloque la fecha de inicio de la campaña');
      return false;     
    }
    if (!this.campaign.startHour) {
      alert('Por favor coloque la hora de inicio de la campaña');
      return false;
    }
    if (!this.campaign.endDate && !this.campaign.dateNotLimit) {
      alert('Por favor coloque la fecha de finalización de la campaña o marque si se mantiene en forma permanente');
      return false;     
    }
    if (!this.campaign.endHour && !this.campaign.dateNotLimit) {
      alert('Por favor coloque la hora de finalización de la campaña o marque si se mantiene en forma permanente');
      return false;
    }
    // TODO: UTILIZAR EL ATRIBUTO campaign PARA PINTAR LOS DIFERENTES ELEMENTOS DEL PROYECTO
    this.campaign.skus = [];
    this.campaign.urls = [];
    this.campaign.urlNotShow = [];
    this.skus.forEach((sku: IElement) => {
      this.campaign.skus.push(sku.text);
    });

    this.urls.forEach((sku: IElement) => {
      this.campaign.urls.push(sku.text);
    });

    this.urlNotShow.forEach((sku: IElement) => {
      this.campaign.urlNotShow.push(sku.text);
    });

    this.campaign.version = `${this.filters.version}_${this.filters.name}`;
    console.log('Campaign:', this.campaign);
    this.campaignService.save(this.campaign).subscribe((resp: ICampaignResponse) => {
      alert('Campaña guardada con exito');
    });
  }

  onAdd() {
    this.versions.forEach((version: IVersion) => {
      if (version.selected) {
        const obj = this.skus.find((sku: IElement) => sku.text === version.id);
        if (!obj) {
          this.skus.push({
            text: version.id,
            selected: false
          });
        }
      }
    })
  }

  setLanguage(lang: ILang) {
    this.selectedLanguage = lang;
  }

  filterSku(event: any) {
    console.log(event);
  }
}
