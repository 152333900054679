import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BroadcastService} from '../../../../../services/bradcast.services';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() element: any;
    @Output() elementChangeHandler: EventEmitter<any> = new EventEmitter();

    actions: any;
    fontFamilies: any;
    state: boolean;
    constructor(private broadcastService: BroadcastService) {
    }

    ngOnInit(): void {
        this.actions = [
            {label: 'Link', value: 'link'},
            {label: 'Mostrar/Ocultar ID', value: 'showOrHide'}
        ];
        this.state = false;
        this.element.action = this.element.action ? this.element.action : {};
        this.element.action.type = this.element.action.type ? this.element.action.type : '';
        this.fontFamilies = [
            {
                name: 'HPSimplified',
                value: 'HPSimplified'
            },
            {
                name: 'HPSimplifiedLight',
                value: 'HPSimplifiedLight'
            },
            {
                name: 'FormaDJRCyrillic',
                value: 'FormaDJRCyrillic'
            },
            {
                name: 'FormaDJRCyrillicLight',
                value: 'FormaDJRCyrillicLight'
            },
            {
                name: 'MicrosoftSegoeUi',
                value: 'MicrosoftSegoeUi'
            },
            {
                name: 'MicrosoftSegoeUiLight',
                value: 'MicrosoftSegoeUiLight'
            },
            {
                name: 'MicrosoftSegoeText',
                value: 'MicrosoftSegoeText'
            },
            {
                name: 'MicrosoftSegoeBold',
                value: 'MicrosoftSegoeBold'
            },
            {
                name: 'MideaGothanBookItalic',
                value: 'MideaGothanBookItalic'
            },
            {
                name: 'MideaGothanLight',
                value: 'MideaGothanLight'
            },
            {
                name: 'MideaGothanBookText',
                value: 'MideaGothanBookText'
            },
            {
                name: 'MideaGothanboldBoldtitle',
                value: 'MideaGothanboldBoldtitle'
            },
            {
                name: 'OsterHelveticaItalic',
                value: 'OsterHelveticaItalic'
            },
            {
                name: 'OsterHelveticaLight',
                value: 'OsterHelveticaLight'
            },
            {
                name: 'OsterHelveticaText',
                value: 'OsterHelveticaText'
            },
            {
                name: 'OsterHelveticaTextBold',
                value: 'OsterHelveticaTextBold'
            },            
        ]
    }


}
