import { IHotSpot, IModalDialogDataKsp } from './../../../hotspots.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService } from 'app/services/modal.service';


@Component({
  selector: 'app-section-ksp',
  templateUrl: './section-ksp.component.html',
  styleUrls: ['./section-ksp.component.scss']
})
export class SectionKspComponent implements OnInit {

  @Input() currentHotspot: IHotSpot;
  @Input() sku: string;
  @Output() handleDataKsp = new EventEmitter<{img: any, title: string, description: string, icon: string}>();

  public imagen:any
  public text:string
  private isOpen = true

  constructor(private modalDialogService: ModalService) { }

  ngOnInit(): void {}

  cbFunc = async (data:IModalDialogDataKsp) => {
    const obj = this.getKsps();
    if (data.index || data.index === 0) {
      obj[data.index].icono = data.icon
      obj[data.index].imgage = data.img
      obj[data.index].title = data.title
      obj[data.index].text = data.description
      return
    }
    this.handleDataKsp.emit({
      img: data.img,
      description: data.description,
      title: data.title,
      icon: data.icon
    });
  }

  getKsps = () => {
    return this.currentHotspot.additionalAttr[0] ? this.currentHotspot.additionalAttr[0].value : []
  }

  moveKspToUp = (index:number) => {
    const array = this.currentHotspot.additionalAttr[0].value
    this.moveKsp(array, index, index = index-1)  
    console.log('moveKspToUp 39:', array)
  }

  moveKspToDown = (index:number) => {
    const array = this.currentHotspot.additionalAttr[0].value
    this.moveKsp(array, index, index = index+1)  
    console.log('moveKspToDown 45:', array)
  }
  
  moveKsp = (listaKsps, fromIndex, toIndex) => {
    console.log('moveKsp 49:', fromIndex, toIndex)
    const element = listaKsps[fromIndex];
    console.log('element 51:', element)
    listaKsps.splice(fromIndex, 1);
    listaKsps.splice(toIndex, 0, element);
  }

  removeKsp = (index) => {
    const array = this.currentHotspot.additionalAttr[0].value
    const element = array[index];
    console.log('element 51:', element)
    array.splice(index, 1);
  }

  editKsp = (index) => {
    const array = this.currentHotspot.additionalAttr[0].value
    const element = array[index];
    this.modalDialogService.selectModalType('EDIT_KSP_MODAL', {
      title: element.title,
      img: element.image,
      description: element.text,
      icon: element.icono,
      sku: this.sku,
      index: index
    },  this.cbFunc)
  }
  
  addKSP = () => {
    this.modalDialogService.selectModalType('EDIT_KSP_MODAL', {
      title: 'Crear KSP',
      sku: this.sku
    },  this.cbFunc)
  }

  openAccordeonElement(open: boolean): void {
    this.isOpen = open
  }

}
