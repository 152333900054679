import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { IHotSpotVersion } from '../../main/indexado/hotspots/hotspots.model'

@Injectable({
  providedIn: 'root'
})
export class HotspotService {

  constructor(
      private http: HttpClient
  ) { }

    varToString = varObj => Object.keys(varObj)[0]

    get(prop: any): Observable<{data: IHotSpotVersion[] }> {
      const filter = this.varToString(prop)
      let objToFilter = {}; objToFilter[filter] = prop[filter]
      return this.http.post<{ data: IHotSpotVersion[] }>(`${env.featureUrl}/hotspots/get`, {
        [filter]: objToFilter[filter]
      });
    }

    getUrlsByRetail(retail:string): Observable<{data: IHotSpotVersion[] }> {
      return this.http.post<{ data: IHotSpotVersion[] }>(`${env.featureUrl}/scriptManagement/get`, {
        filter: [
          // {key:'sku', value: sku}, //TODO: se puede mejorar filtro de busquedad, pero en el momento seria un envio innecesario
          {key: "lang", value: "esCL"}
        ],
        retail: retail,
      });
    }

    save(versions: IHotSpotVersion[], productId: string): Observable<any> {
      return this.http.post(`${env.featureUrl}/hotspots/save`, {
          product_id: productId,
          versions
      });
    }

    saveBulkloadHotspots(payload:any): Observable<any> {
      return this.http.post(`${env.featureUrl}/hotspots/bulkload`, {
        baseSku: payload.baseSku,
        skuList: payload.skuList,
        target:{
          targetContainer: payload.targetContainer,
          targetContainerType: payload.targetContainerType,
          targetRetail: payload.targetRetail
        }
      });
    }

    searchSkus(payload: any): Observable<any> {
      return this.http.post(`${env.featureUrl}/modelo/get`, {
          language:payload.language,
          skuList: payload.skuList
      });
    }

    getConfigurations(): Observable<any> {
      return this.http.get(`${env.featureUrl}/scriptManagement/getAll?labels=true`);
    }
}
