import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { HOTSPOT_TYPES, IHotSpot, IHotSpotAdditionalAttr, IHotSpotVersion } from './hotspots.model';
import { HotspotService } from '../../../services/hotspots/hotspot.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BACKGROUND_DEFAULT } from '../../../../assets/images/backgrounds/b64/default';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import { ModalService } from 'app/services/modal.service';
import { CONFIRM_DELETE_HOTSPOT, confirm_delete_hs_version, ERROR_SAVE_INFO, INFO_SAVED_SUCCESSFULLY } from 'app/Messages';
import { SCRIPT_TAG } from './script'
import { environment } from 'environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-hotspots',
  templateUrl: './hotspots.component.html',
  styleUrls: ['./hotspots.component.scss']
})
export class HotspotsComponent implements OnInit {
  
  public versions: IHotSpotVersion[];
  public currentHotspot: IHotSpot;
  public currentVersion: IHotSpotVersion;
  public id: string;
  public isPreview: boolean = false;
  private isLoading: boolean;
  private scrollableContentHeight: number
  private availableBulkLoad:any[]
  private heightThumbnailImg: number
  private widthThumbnailImg: number
  // this.imgPolicyVerification = './assets/img/con001/astPSEG_IMG_BOX.png';

  @ViewChild('backgroundImagePicker')
  backgroundImagePicker

  constructor(
    private hotSpotsService: HotspotService,
    private route: ActivatedRoute,
    private router: Router,
    private fuseProgressBarService: FuseProgressBarService,
    private modalDialogService: ModalService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.versions = [];
    this.currentHotspot = null;
    this.currentVersion = null;
    this.id = '';
    this.isLoading = false;
    this.availableBulkLoad=[]
    this.readImgSizes(BACKGROUND_DEFAULT)
  }

  ngOnInit(): void {
    console.log('prev ID:::');
    this.id = this.route.snapshot.paramMap.get('id');
    console.log('post ID:::', this.id);
    this.fuseProgressBarService.show();
    this.isLoading = true;
    this.hotSpotsService.get({id: this.id})
    .subscribe((response: { status: number, data: IHotSpotVersion[] }) => {
      this.versions = response.status === 200 ? response.data : [];
      this.currentVersion = this.versions.length > 0 ? this.versions[0] : null;
      if (this.currentVersion){
        this.currentVersion.backgroundImage = BACKGROUND_DEFAULT
      }
      this.currentHotspot = this.currentVersion && this.currentVersion.hotspots.length > 0 ? this.currentVersion.hotspots[0] : null;
      this.fuseProgressBarService.hide();
      this.isLoading = false;
      if (this.versions.length > 0){
        for (let index = 0; index < this.versions.length; index++) {
          this.versions[index].backgroundImage = BACKGROUND_DEFAULT
          this.availableBulkLoad.push({id:this.versions[index].version,available:true})
        }

      }
      if(this.versions.length > 0){
        this.readImgSizes(this.currentVersion.backgroundImage)
      }
    }, (err: any) => {
      alert(err)
      this.versions = [];
      this.fuseProgressBarService.hide();
      this.isLoading = false;
    }); 

    
    const hotspotsHeader = document.getElementById('hotspotsHeader') as HTMLElement

    this.setContentScrollParams(hotspotsHeader)

    window.onresize = () => {
      this.setContentScrollParams(hotspotsHeader)
      this.setHeightBackground();
    }
  }

  setContentScrollParams(fixedElement) {
    const fixedElementYOffset = fixedElement.getBoundingClientRect().top
    const fixedElementHeight = fixedElement.offsetHeight
    const contentOffset = fixedElementYOffset + fixedElementHeight

    const availableHeight = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight

    this.scrollableContentHeight = availableHeight - contentOffset
  }

  onClickBackgroundImgSelector(): void {
    this.backgroundImagePicker.nativeElement.click();
  }

  addVersion(): void {
    this.fuseProgressBarService.show();
    this.isLoading = true;
    let idVersion:string = ''
    const id:string = 'TEMPLATES'
    this.hotSpotsService.get({id})
    .subscribe((response: { status: number, data: IHotSpotVersion[] }) => {
      let versions = response.status === 200 && response.data.length > 0 ? response.data : null;
      console.log('versions 119',versions)
      let versionAvalaibles:string = ''
      let versionsTemplates:{version:string, index, newVersion:IHotSpotVersion}[] = []
      if (versions) {
        for(let i=0; i < versions.length; i++) {
          if (versions[i].version) {
            versionsTemplates[i] = { version: versions[i].version, index:Number(i + 1), newVersion:versions[i] }
            versionAvalaibles += versionsTemplates[i].version + '(' + versionsTemplates[i].index + ') ';
          } 
        }        
        idVersion = prompt(`Cuál plantilla deseas replicar: ${versionAvalaibles} (0 libre)?`);
        if ((parseInt(idVersion) > versions.length)) {
          alert(`Por el momento solo están disponibles las plantillas: ${versionAvalaibles} ó 0 para versión libre: (0 libre)?`);
          return
        }
        let newVersion: IHotSpotVersion;

        for(let i=0; i < versionsTemplates.length; i++) {
          if(versionsTemplates[i].version === idVersion || versionsTemplates[i].index == idVersion) {
            newVersion = versionsTemplates[i].newVersion;
            break
          } 
          if (idVersion === '0') {
            newVersion = {
              id: `aleatory_${Math.round(Math.random() * 100000)}`,
              language: 'esCL',
              visible: true,
              template: '',
              version: 'NEW',
              backgroundImage: BACKGROUND_DEFAULT,
              hotspots: [],
              container: '',
              containerType: '',
              retail: ''
            };
          }
        }
        this.versions.push(newVersion);
        this.availableBulkLoad.push({id:newVersion.version,available:false});
        this.currentVersion.backgroundImage = BACKGROUND_DEFAULT
      }
      this.fuseProgressBarService.hide();
      this.isLoading = false;

      return;
    }, (err: any) => {
      alert(err)
      this.versions = [];
      this.fuseProgressBarService.hide();
      this.isLoading = false;
    }); 
  }

  addHotSpot(): void {
    const emptyHotSpot: IHotSpot = {
      additionalAttr: [],
      backgroundIcon: '#ffffff',
      backgroundImage: '',
      colorIcon: '#660000',
      id: `aleatory_${Math.round(Math.random() * 100000)}`,
      layout: {
        posX: 45,
        posY: 45,
        width: 40,
        height: 40
      },
      text: '',
      type: HOTSPOT_TYPES.EMPTY,
      visible: true
    };
    this.currentHotspot = emptyHotSpot;
    this.currentVersion.hotspots.push(emptyHotSpot);
  }
  setHeightBackground():void{
    const heightPorcentage = this.heightThumbnailImg * 100 / this.widthThumbnailImg
    const container = document.getElementsByClassName('hotspot__section')[0] as HTMLElement
    const effectiveWidth = container?.offsetWidth 
    // console.log("effectiveWidth",effectiveWidth)
    const finalImgHeight = (effectiveWidth * heightPorcentage)/100
    // console.log("finalHeight",finalImgHeight)
    const img = document.getElementsByClassName('hotspot__thumbnail')[0] as HTMLElement
    img.style.height = finalImgHeight + 'px'

    // console.log('img.style.height',img.style.height)
  }
  changeBackground(event:any): void {
    const file = event.target.files[0];
    this.imageReader(file);
  }

  imageReader(file:any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let arrayBuffer:any
      //Set the Base64 string return from FileReader as source. 
      arrayBuffer = e.target.result;
      this.readImgSizes(arrayBuffer);
      this.currentVersion.backgroundImage = reader.result.toString();
    };
  }

  private readImgSizes(arrayBuffer : any) {
    let image = new Image();
    image.src = arrayBuffer;
    image.onload = () => {
      this.heightThumbnailImg = image.naturalHeight;
      this.widthThumbnailImg = image.naturalWidth;
      return true;
    };
  }

  select(id: string): void {
    this.currentVersion = this.versions.find((elem: IHotSpotVersion) => elem.id === id) || null;
    this.currentHotspot = this.currentVersion.hotspots.length > 0 ? this.currentVersion.hotspots[0] : null;
    // TODO: Validar mejor forma de hacer esto
    if (this.currentVersion){
      setTimeout(() => {
        this.setHeightBackground()
      }, 100);
    }
  }

  onChangeHotspotConfigGrandpa(obj: IHotSpotAdditionalAttr): void {
    this.currentHotspot[obj.key] = obj.value;
  }

  onChangeLayoutHotspotGrandpa(obj: IHotSpotAdditionalAttr): void {
    this.currentHotspot.layout[obj.key] = obj.value;
  }

  getIcon(type: HOTSPOT_TYPES): string {
    switch (type) {
      case HOTSPOT_TYPES.EMPTY:
          return '../../../../assets/images/hotspot/empty.svg';
      case HOTSPOT_TYPES.GALLERY:
          return '../../../../assets/images/hotspot/gallery.svg';
      case HOTSPOT_TYPES.KSP:
          return '../../../../assets/images/hotspot/ksp.svg';
      case HOTSPOT_TYPES.TEXT:
          return '../../../../assets/images/hotspot/text.svg';
      case HOTSPOT_TYPES.VIDEO:
          return '../../../../assets/images/hotspot/video.svg';
      default:
          return '../../../../assets/images/hotspot/empty.svg';
    }
  }

  selectedHotspot(hotspot: IHotSpot): void {
    this.currentHotspot = hotspot;
  }

  drop(event: CdkDragDrop<string[]>): void {
    console.log(event);
  }

  onSave(): void {
    this.isLoading = true;
    this.fuseProgressBarService.show();
    this.versions.forEach(function(v){ delete v.backgroundImage });
    this.hotSpotsService.save(this.versions, this.id)
    .subscribe((response: any) => {
        if (response.status === 200) {
          this.availableBulkLoad.forEach(elem => elem.available=true)
          this.isLoading = false;
          let i=0
          this.availableBulkLoad.forEach(elem => {
            elem.id=this.versions[i].version
            elem.available=true
            i++
          })
          this.modalDialogService.selectModalType('INFOMODAL', INFO_SAVED_SUCCESSFULLY);
          // this.router.navigate(['/cap/list']);   
        } else {
          this.modalDialogService.selectModalType('ERRORMODAL', {
            message: ERROR_SAVE_INFO,
            error: 'Error guardando la información'
          });
          this.isLoading = false;
        }
        this.isLoading = false;
        this.fuseProgressBarService.hide();
      },
      (err: any) => {
        this.isLoading = false;
        this.fuseProgressBarService.hide();
        this.modalDialogService.selectModalType('ERRORMODAL', {
            message: ERROR_SAVE_INFO,
            error: err
        })
      });
  }

  onDuplicateHotspot(): void {
    const newHotSpot: IHotSpot = { 
      ...this.currentHotspot,
      id: `aleatory_${Math.round(Math.random() * 100000)}`,
      layout: { ...this.currentHotspot.layout,
        posX: Math.round(Math.random() * 80),
        posY: Math.round(Math.random() * 80)
      }
    };
    this.currentVersion.hotspots.push(newHotSpot);
  }

  onDeleteHotspot(): void {
    this.modalDialogService.selectModalType('BUTTONCONFIRM', CONFIRM_DELETE_HOTSPOT, confirm => {
      if (confirm) {
        this.currentVersion.hotspots = this.currentVersion.hotspots.filter((elem: IHotSpot) => elem.id !== this.currentHotspot.id);
        this.currentHotspot = this.currentVersion.hotspots.length > 0 ? this.currentVersion.hotspots[0] : null;
      }
    })
  }

  onDuplicateVersion(): void {
    const newVersion: IHotSpotVersion = { ...this.currentVersion };
    newVersion.id = `aleatory_${Math.round(Math.random() * 100000)}`;
    newVersion.version = `${newVersion.version}-copy`;
    this.versions.push(newVersion);
  }

  onDeleteVersion(id: string): void {
    this.modalDialogService.selectModalType('BUTTONCONFIRM', confirm_delete_hs_version(this.currentVersion.version), confirm => {
      if (confirm) {
        this.versions = this.versions.filter((elem: IHotSpotVersion) => elem.id !== id)
    
        if (this.currentVersion && this.currentVersion.id === id) {
          this.currentVersion = null
        }
      }
    })
  }

  changeHotspotPosition(event): void {
    if (!this.currentHotspot) {
        return;
    }
    const posX = event.layerX;
    const posY = event.layerY;
    this.currentHotspot.layout.posX = 100 * posX / 800;
    this.currentHotspot.layout.posY = 100 * posY / 400;
    console.log(event);
  }

  changeAttr(obj: IHotSpotAdditionalAttr): void {
      const elem = this.currentHotspot.additionalAttr.find((m: IHotSpotAdditionalAttr) => m.key === obj.key);
      if (elem) {
          elem.value = obj.value;
      } else {
          this.currentHotspot.additionalAttr.push(obj);
      }
  }

  moveHotspot(address: string): void {
      switch (address) {
          case 'up':
              this.currentHotspot.layout.posY -= 1;
              break;
          case 'down':
              this.currentHotspot.layout.posY += 1;
              break;
          case 'left':
              this.currentHotspot.layout.posX -= 1;
              break;
          case 'right':
              this.currentHotspot.layout.posX += 1;
              break;
      }
  }

  showPreview(state: boolean): void {
    this.isPreview = state
  }

  onCopy() {
    const sc = SCRIPT_TAG({
      sku: this.id,
      lang: this.currentVersion.language,
      version: this.currentVersion.version,
      urlHotspots: `${environment.featureUrl}/hotspots/get`,
      urlLogs: `${environment.featureUrl}/logsFront/log`,
      container: {
        id: this.currentVersion.container,
        type: this.currentVersion.containerType
      }
    });

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = sc;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);
  }  
}
