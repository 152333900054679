import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {IHotSpot, IHotSpotAdditionalAttr, IModalDialogDataText} from "../../../hotspots.model";
import { ModalService } from 'app/services/modal.service';
@Component({
  selector: 'app-section-text',
  templateUrl: './section-text.component.html',
  styleUrls: ['./section-text.component.scss']
})
export class SectionTextComponent implements OnInit {

  @Input() sku: string;
  @Input() currentHotspot: IHotSpot;
  @Output() changeAttr = new EventEmitter<IHotSpotAdditionalAttr>();
  @Output() handleDataText = new EventEmitter<{title: string, description: string, icon: string}>();

  // textValue: string;

  private isOpen = true

  constructor(private modalDialogService: ModalService) { }

  ngOnInit(): void {
      const obj = this.currentHotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'TEXT');
      // this.textValue = obj ? obj.value : '';
  }

  onChangeAttr(event: any, field: string): void {
      this.changeAttr.emit({
         key: field,
         value: event.target.value
      });
  }

  openAccordeonElement(open: boolean): void {
    this.isOpen = open
  }

  getTexts(): {title: string, icono: string, text: string}[] {
    const obj = this.currentHotspot ? this.currentHotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'TEXT') : null;
    return obj ? obj.value : null;
  }
  
  cbFunc = async (data:IModalDialogDataText) => {
    const obj = this.getTexts();
    if (data.index || data.index === 0) {
      obj[data.index].icono = data.icon
      obj[data.index].title = data.title
      obj[data.index].text = data.description
      return
    }
    if (obj) {
      obj.push({
        text: data.description,
        title: data.title,
        icono: data.icon
      })
    } else {
      console.log('data',data)
      if (data.index || data.index === 0) {
        obj[data.index].icono = data.icon
        obj[data.index].title = data.title
        obj[data.index].text = data.description
        return
      }
      if (obj) {
        console.log('obj',obj)

        obj.push({
          text: data.description,
          title: data.title,
          icono: data.icon
        })
      } else {
        console.log('TEXT',data)

        this.currentHotspot.additionalAttr.push({
          key: 'TEXT',
          value: [{
            text: data.description,
            title: data.title,
            icono: data.icon
          }]
        });
      }
    }

    // this.handleDataText.emit({
    //   title: data.title,
    //   description: data.description,
    //   icon: ''
    // });
  }
  
  addText = () => {
    this.modalDialogService.selectModalType('EDIT_TEXT_MODAL', {
      title: 'Crear Texto',
      sku: this.sku
    },  this.cbFunc)
  }

  moveToUp = (index:number) => {
    const array = this.currentHotspot.additionalAttr[0].value
    this.moveText(array, index, index = index-1)  
    console.log('moveKspToUp 39:', array)
  }

  moveToDown = (index:number) => {
    const array = this.currentHotspot.additionalAttr[0].value
    this.moveText(array, index, index = index+1)  
    console.log('moveKspToDown 45:', array)
  }
  
  moveText = (listaKsps, fromIndex, toIndex) => {
    console.log('moveKsp 49:', fromIndex, toIndex)
    const element = listaKsps[fromIndex];
    console.log('element 51:', element)
    listaKsps.splice(fromIndex, 1);
    listaKsps.splice(toIndex, 0, element);
  }

  removeText = (index) => {
    const array = this.currentHotspot.additionalAttr[0].value
    const element = array[index];
    console.log('element 51:', element)
    array.splice(index, 1);
  }

  editText = (index) => {
    const array = this.currentHotspot.additionalAttr[0].value
    const element = array[index];
    this.modalDialogService.selectModalType('EDIT_TEXT_MODAL', {
      title: element.title,
      description: element.text,
      icon: element.icono,
      sku: this.sku,
      index: index
    },  this.cbFunc)
  }

  // getTexts = () => {
  //   return this.currentHotspot.additionalAttr[0] ? this.currentHotspot.additionalAttr[0].value : []
  // }

  
}
