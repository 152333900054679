// error messages
export const ERROR_GET_CATEGORIES_LIST = 'No se pudo obtener la lista de categorías.'
export const ERROR_GET_USERS_LIST = 'No se pudo obtener la lista de usuarios.'
export const ERROR_DELETE_ENTRY = 'Ha ocurrido un error eliminando la entrada.'
export const ERROR_DELETE_USER = 'Ha ocurrido un error eliminando al usuario.'
export const ERROR_UPDATE_USER = 'No se ha podido actualizar el usuario.'
export const ERROR_SAVE_INFO = 'No se pudo guardar la información. Intente de nuevo.'
export const ERROR_LOAD_PROFILES = 'No se pudieron cargar los perfiles.'
export const ERROR_GET_SKU_LIST = 'No se pudo obtener la lista de skus.'

// info messages
export const INFO_ENTRY_WONT_SINCRONIZE = 'La entrada no se sincronizará.'
export const INFO_DELETED_USER = 'Usuario eliminado.'
export const INFO_UPDATED_USER = 'Usuario actualizado.'
export const INFO_SAVED_SUCCESSFULLY = 'Se guardó exitosamente.'
export const INFO_NOT_TABLE_ELEMENT = 'Not table element.'

// confirm messages
export const confirm_delete_hs_version = title => {return `Si confirma esta acción, eliminará la versión '${title}'.`}
export const CONFIRM_DELETE_HOTSPOT = 'Si confirma esta acción, el hotspot seleccionado será eliminado.'
export const CONFIRM_DELETE_IMAGE = 'Si confirma esta acción, la imagen seleccionada será eliminada.'
export const CONFIRM_DELETE_ELEMENT = 'Si confirma esta acción, el elemento seleccionado se eliminará.'
export const CONFIRM_DELETE_ROW = '¿Está seguro que desea eliminar la fila y todos sus elementos?'
export const CONFIRM_DELETE_RVERSION = '¿Está seguro que desea eliminar esta versión?'