import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalDialogDataGallery } from '../../../hotspots.model';

@Component({
  selector: 'app-modal-edit-gallery',
  templateUrl: './modal-edit-gallery.component.html',
  styleUrls: ['./modal-edit-gallery.component.scss']
})
export class ModalEditGALLERYComponent implements OnInit {

  public images: string[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalEditGALLERYComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalDialogDataGallery,
  ) {
    console.log('Images_:;;', this.data);
    this.images = this.data.images;
    // this.hotspotService.getUrlsByRetail().subscribe((responseScriptManager: any) => {
    //   const urlsByRetail = responseScriptManager.data.value
    //   if (urlsByRetail) {
        
    //     // const filterField = this.currentHotspot.additionalAttr.find(hotspot => hotspot.key === 'TEXT')
    //     // console.log(filterField.value.find(text => text.title === 'Memoria Ram'))
    //     // response.data.value.find(url => url.indexOf())
    //     this.modelService.getModels(this.sku, 'HP').subscribe((response:any) => {
    //       response[0]
    //       const urlBanner = urlsByRetail[1].fullUrl
    //       console.log('urlBanner',urlBanner)
    //       this.modelService.getBanners(urlBanner, `https://front.indexado.production.alquimio.cloud/spec?modelId=${response[0].id}&lang=esCL`).subscribe((response:any) => {
    //         this.banners = response
    //         console.log('banners',response)
    //       })
    //     })
    //   }
    // }, 
    // (err: any) => {
    //   console.log('err',err)
    // });
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close({action: 'cancel'});
  }

  onClick() {

  }

  addImage(event: any): void {
    // this.data.icon = event.target.files[0];
  }

  // handleAddImageFromDevice(event): void {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {      
  //     const obj = this.getImages();
  //     if (obj) {
  //       obj.push(reader.result.toString());
  //     } else {
  //       this.currentHotspot.additionalAttr.push({
  //         key: 'IMAGES',
  //         value: [reader.result.toString()]
  //       });
  //     }
  //   };
  // }

}
