import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {ImagesService} from './images.service';

import * as _ from 'lodash';
import {debounceTime, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-multimedia',
    templateUrl: './multimedia.dialog.component.html',
    styleUrls: ['./multimedia.dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultimediaDialogComponent implements OnInit {
    afuConfig: any;
    filesUploaded = [];
    items;
    itemSelected: any;
    itemsSelected = [];
    isMultiple: boolean;
    searchTextChanged = new Subject<string>();
    fileUploadUrl: string;
    fnOnUpload;

    constructor(public dialogRef: MatDialogRef<MultimediaDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public itemsService: ImagesService
    ) {
    }

    subscription;

    ngOnInit(): void {
        this.fileUploadUrl = this.data.fileUploadUrl;
        switch (this.data.type) {
            case 'images':
                this.fnOnUpload = this.refreshImages
                break;
            case 'CAP':
                this.fnOnUpload = this.logUploaded;
                break;
            default:
                this.fnOnUpload = this.refreshImages
                break;
        }

        this.itemsService.getImages().subscribe((data) => {
            this.items = data;
        });
        this.isMultiple = this.data.multi || false;

        this.subscription = this.searchTextChanged.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            mergeMap(search => this.itemsService.getImages(search))
        ).subscribe((data) => {
            this.items = data;
        });
    }

    uploadedResponse = [];
    logUploaded(res): void {
        if(res && res.event && res.event.status && res.event.type && res.event.type == 4 && res.event.body){
            this.uploadedResponse.push(res.event.body);
        }
        console.log('uploadResponse :' , this.uploadedResponse);
    }

    refreshImages(): void {
        this.itemsService.getImages().subscribe((data) => {
            this.items = data;
        });
    }

    fileUploaded($event): void {
        this.filesUploaded.push($event.event.body);
        console.log('File Uploaded ', this.filesUploaded);
    }

    clickOnItem(item): void {
        if (this.isSelected(item)) {
            this.removeSelection(item);
        } else {
            this.addToSelection(item);
        }
        console.log('ITEM SELECTED : ', this.itemSelected);
        console.log('ITEMS : ', this.itemsSelected);
    }

    addToSelection(item): void {

        if (this.isMultiple) {
            if (!this.itemsSelected) {
                this.itemsSelected = [];
            }
            item.selected = true;
            this.itemsSelected.push(item);
        } else {
            _.forEach(this.items, (itemInSelection) => {
                itemInSelection.selected = false;
            });
            item.selected = true;
            this.itemSelected = item;
        }
    }

    isSelected(item): boolean {
        let isSelected;
        if (this.isMultiple) {
            isSelected = _.includes(this.itemsSelected, item)
        } else {
            isSelected = item == this.itemSelected
        }
        return isSelected;
    }

    removeSelection(item): void {
        item.selected = false;
        if (!this.isMultiple) {
            this.itemsSelected = null;
        } else {
            this.itemsSelected = _.filter(this.itemsSelected, (itemInSelection) => {
                return itemInSelection.storageId !== item.storageId
            });
        }
    }

    saveSelectedImage() {
        if (this.isMultiple) {
            this.dialogRef.close(this.itemsSelected);
        } else {
            this.dialogRef.close(this.itemSelected);
        }
    }


}
