import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HOTSPOT_TYPES, IHotSpot, IHotSpotAdditionalAttr, IHotSpotVersion, IModalDialogDataKsp} from '../../hotspots.model';
import { ModalService } from 'app/services/modal.service';
import { ModelService } from 'app/services/model.service';
import { IndexadoRows } from '../../hotspots.model';
import { CONFIRM_DELETE_IMAGE } from 'app/Messages';
@Component({
  selector: 'app-hotspots-sidebar',
  templateUrl: './hotspots-sidebar.component.html',
  styleUrls: ['./hotspots-sidebar.component.scss']
})
export class HotspotsSidebarComponent implements OnInit {

  private contenedor: any;
  private sidebar__sections: any;
  private hotspot__section: any;
  imagesFromIndexado: {cardId:string,backgroundImageId:string, contents:any[]}[] = [];
  imagesForKsp: string[] = []
  diferenciators: any[];
  @Input() currentHotspot: IHotSpot;
  @Input() currentVersion: IHotSpotVersion;
  @Input() sku: string;
  @Input() availableBulkLoad: any;

  @Output() deleteHotspot = new EventEmitter();
  @Output() duplicateHotspot = new EventEmitter();
  @Output() deleteVersion = new EventEmitter();
  @Output() duplicateVersion = new EventEmitter();

  @Output() changeHotspotConfig = new EventEmitter<IHotSpotAdditionalAttr>();
  @Output() changeLayoutHotspot = new EventEmitter<IHotSpotAdditionalAttr>();
  @Output() changeAttr = new EventEmitter<IHotSpotAdditionalAttr>();

  private hotspotTypes: HOTSPOT_TYPES[];
  constructor(
    private modalDialogService: ModalService,
    private modelService: ModelService
    ) {}

  ngOnInit(): void {
    this.hotspotTypes = [
      HOTSPOT_TYPES.EMPTY,
      HOTSPOT_TYPES.GALLERY,
      HOTSPOT_TYPES.KSP,
      HOTSPOT_TYPES.TEXT,
      HOTSPOT_TYPES.VIDEO
    ];
  }

  handleCreateHotspotsFromIndexadoDad(create: boolean): void {
    if(create) {
      this.modelService.getModels(this.sku, 'HP').subscribe((response: any) => {
        this.modelService.getModelWithLang(response[0].id, null).subscribe(modelv2Data => {  
          modelv2Data.rows.forEach(row => {
            if(row.cards) {
              row.cards.forEach(card => {
                card.cardId === IndexadoRows.imagesKspCards ? this.imagesFromIndexado.push(card) : ''
              });
            }
          });
  
          if(this.imagesFromIndexado.length > 0) {
            if(!this.imagesFromIndexado[0].backgroundImageId) { // Asignar primer imagen a Ksp cuando Indexado está configurado sin video 
              if(this.imagesFromIndexado[0].contents) {
                const contentFirstImage = this.imagesFromIndexado[0].contents.find(content => content.contentId === IndexadoRows.imagesKspCardsContents)
                this.imagesForKsp.push(`https://media.alquimio.cloud/images/@thumbs_1200_${contentFirstImage.backgroundImageId}.webp`)
              }
            } else { // Asignar primer imagen a Ksp cuando Indexado tiene video 
              this.imagesFromIndexado[1].backgroundImageId ? this.imagesForKsp.push(`https://media.alquimio.cloud/images/@thumbs_1200_${this.imagesFromIndexado[1].backgroundImageId}.webp`) : ''
            }
            // Asignar segunda y tercer imagen a Ksp desde Indexado 
            this.imagesFromIndexado[2].backgroundImageId ? this.imagesForKsp.push(`https://media.alquimio.cloud/images/@thumbs_1200_${this.imagesFromIndexado[2].backgroundImageId}.webp`) : ''
            this.imagesFromIndexado[3].backgroundImageId ? this.imagesForKsp.push(`https://media.alquimio.cloud/images/@thumbs_1200_${this.imagesFromIndexado[3].backgroundImageId}.webp`) : ''
          }
          for(let i=0; i<this.diferenciators.length; i++) {
            this.diferenciators[i].mainImage = this.imagesForKsp[i]
          }
        }, error => {
          console.log('Not content', error)
        });
        // if (response.length > 0) {
        //   const model = response[0];
        //   if (model.langs && model.langs.esCL && model.langs.esCL.diferenciadores) {
        //     this.diferenciators = model.langs.esCL.diferenciadores.map((differ: any) => {
        //       return differ;
        //     });
        //   }
        // }
        // this.kspSelected = -1;
      },
      (err: any) => {
        //  this.diferenciators = [];
      });
    }
  }

  onChangeHotspotConfigDad(obj: IHotSpotAdditionalAttr): void {
    this.availableBulkLoad.forEach(elem => {if(elem.id===this.currentVersion.version)elem.available=false})
    this.currentHotspot[obj.key] = obj.value;
  }

  onChangeVersionConfigDad(obj: IHotSpotAdditionalAttr): void {
    this.availableBulkLoad.forEach(elem => {if(elem.id===this.currentVersion.version)elem.available=false})
    this.currentVersion[obj.key] = obj.value;
  }

  onChangeLayoutHotspotDad(obj: IHotSpotAdditionalAttr): void {
    this.availableBulkLoad.forEach(elem => {if(elem.id===this.currentVersion.version)elem.available=false})
    this.currentHotspot.layout[obj.key] = obj.value;
  }

  onDeleteHotspot(): void {
    this.deleteHotspot.emit();
  }

  onDuplicateHotspot(): void {
    this.duplicateHotspot.emit();
  }

  onChangeAttr(obj: IHotSpotAdditionalAttr): void {
      this.changeAttr.emit(obj);
  }

  getImages(): string[] {
    const obj = this.currentHotspot ? this.currentHotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'IMAGES') : null;
    return obj ? obj.value : null;
  }

  getKsp(): {title: string, icono: string, text: string, image: string}[] {
    const obj = this.currentHotspot ? this.currentHotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'KSP') : null;
    return obj ? obj.value : null;
  }
  
  handleDataKsp(data: IModalDialogDataKsp): void {
    console.log('data135', data)
    const file = data.img;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {      
      const obj = this.getKsp();
      console.log('obj', obj)
      if (obj) {
        obj.push({
          image: reader.result.toString(),
          text: data.description,
          title: data.title,
          icono: data.icon
        })
      } else {
        this.currentHotspot.additionalAttr.push({
          key: 'KSP',
          value: [{
            image: reader.result.toString(),
            text: data.description,
            title: data.title,
            icono: data.icon
          }]
        });
      }
    };
  }


  handleDeleteImage(i: number): void {
    this.modalDialogService.selectModalType('BUTTONCONFIRM', CONFIRM_DELETE_IMAGE, confirm => {
      if (confirm) {
        let obj = this.currentHotspot ? this.currentHotspot.additionalAttr.find((elem: IHotSpotAdditionalAttr) => elem.key === 'IMAGES') : null;
        if (obj) {
          obj.value = obj.value.filter((elem: string, index: number) => index !== i);
        }
      }
    })
  }

  handleSort(list: {row: number, direction: number}) {
    const exchange = (arr: string[], x: number, y: number) => {
      [arr[x], arr[y]] = [arr[y], arr[x]];
      console.log(arr);
    }

    let obj = this.getImages();
    if (list.direction === 1) {
      exchange(obj, list.row, list.row + 1);
    } else {
      exchange(obj, list.row - 1, list.row);
    }
  }


}
