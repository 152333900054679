import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment as env} from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
/**
 * Servicio que obtiene y gestiona los usuarios locales de artefacto inflow-api
 */
export class ImagesService {

    /**
     * Constructor
     *
     * @param {HttpClient} httpClient
     */
    filter : any;
    constructor(
        private httpClient: HttpClient
    ) {
    }

    getImages(name = null, page = 0): Observable<any[]> {
        let params = new HttpParams();

        this.filter = {
            limit:300,
            order : 'createdAt DESC'
        };
        if (name !== null) {
            this.filter.where = {
                filename: {
                    like: '.*' + name + '.*', options: 'igm'
                }
            };
        }
        this.filter.offset = page * this.filter.limit;
        params = params.append('filter', JSON.stringify(this.filter));
        return this.httpClient.get<any[]>(`${env.apiUrl}/images`, { params: params });
    }


}
